





// Core
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'UsersContainer',
})
export default class UsersContainerComponent extends Vue {}
